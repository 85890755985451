.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 75px;
  //   background-color: aqua;
}
.flex {
  display: flex;
  align-items: center;
}
.userTitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  margin: 0px !important;
  padding-left: 10px;
}
