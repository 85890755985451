.overflow {
  overflow: scroll;
  scrollbar-color: #f1f6f7 #ffffff;
  scrollbar-width: thin;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  // ::-webkit-scrollbar {
  //   display: none;
  // }
}

.table,
td {
  border: 1px solid #e8e8e8;
  text-align: left;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.table {
  //   border-collapse: collapse; //for text ...
  // table-layout: fixed; //for text ...
  border-spacing: 0px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;

  th {
    padding: 15px;
    background: #f1f6f7;
    font-weight: 700;
    color: #000;
    white-space: nowrap;
    // text-overflow: ellipsis; //for text ...
    // width: 100px; //for text ...
    // overflow: hidden; //for text ...
  }
  td {
    padding: 15px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.table tr:first-child th:first-child {
  //   border: 2px solid orange;
  border-top-left-radius: 4px;
}

.table tr:first-child th:last-child {
  //   border: 2px solid green;
  border-top-right-radius: 4px;
}

.table tr:last-child td:first-child {
  //   border: 2px solid orange;
  border-bottom-left-radius: 4px;
}

.table tr:last-child td:last-child {
  //   border: 2px solid green;
  border-bottom-right-radius: 4px;
}
