.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.name {
  margin: 0px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 20px;
  }
}
.subContainer {
  padding-left: 7%;
  padding-right: 2%;
  @media screen and (width< 400px) {
    padding: 3%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 6%;
  }
}
.title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.des {
  color: #000;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.des1 {
  color: #000;
  margin: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media screen and (width< 776px) {
    padding-bottom: 20px;
  }
}
.input {
  width: 335px;
  height: 56px;
  border-radius: 4px;
  background-color: #fff;
}
.flexContainer {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
  margin-top: 20px;
  @media screen and (width< 776px) {
    display: contents;
  }
}

.left {
  // width: 30%;
  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* Standard syntax */
  @media screen and (width< 776px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.right {
  width: 65%;
  margin-left: 2%;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 25px 20px 30px 30px;
  @media screen and (width< 776px) {
    width: fit-content;
  }
}

.buttonFlex {
  justify-content: flex-end;
  display: flex;
  margin-top: 25px;
}
