.container {
  display: flex;
  flex-direction: column;
  // height: 100vh !important;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
}
.subContainer {
  padding-left: 7%;
  padding-right: 7%;
  height: 90vh;
  display: flex;
  align-items: center;

  @media screen and (width< 400px) {
    padding: 3%;
    display: contents !important;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 3%;
    display: contents !important;
  }
}
.img {
  height: 70vh;
  width: auto;
  @media screen and (width< 400px) {
    height: 60vh;
    padding: 20px 0px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    height: 60vh;
    padding: 30px 0px;
  }
}
.title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}
.btnText {
  color: #fff;
  text-align: center;
  /* M3/label/large */
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  padding-left: 8px;
  margin: 0px;
  white-space: nowrap;
}
.email {
  margin-right: 20px;
  display: flex;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #3f8ea3;
  padding: 15px 30px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;

  cursor: pointer;
  &:hover {
    background-color: #2e6777;
  }

  @media screen and (width< 400px) {
    font-size: 14px;
    padding: 10px 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.edit {
  display: flex;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  padding: 15px 35px;
  border: 1px solid #e8e8e8;

  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;

  cursor: pointer;

  @media screen and (width< 400px) {
    font-size: 14px;
    padding: 10px 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.right {
  margin-left: 7%;
  @media screen and (width< 400px) {
    margin-left: 2%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    margin-left: 3%;
  }
}
.pdfImg {
  height: 100%;
  width: 100%;
}
.round {
  border-radius: 3px;
  background: #0b3f45;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.backToList {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  padding-bottom: 20px;
  padding-left: 2px;
}
.flex {
  display: flex;
  align-items: center;
  margin-top: 80px;
}
.flex2 {
  display: flex;
}
.innerFlex {
  width: 33.33% !important;
  font-size: 90px;
  font-weight: 400;
  color: #02b5c4;
}
.innerFlex2 {
  width: 33.33% !important;
  font-size: 25px;
  margin-top: 10px;
  color: #000;
}
.textTitle {
  color: #00b5c4;
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}
.des {
  color: #000;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  line-height: 2;
}
.leftFlex {
  height: 230px;
  width: 40%;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.midFlex {
  height: 230px;
  width: 30%;
  background-color: #4b39b5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rightFlex {
  height: 230px;
  width: 30%;
  background-color: #6bd1cb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flexTitle {
  color: #000;
  font-size: 40px;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 20px;
  text-align: center;
}
.flexDes {
  color: #000;
  font-size: 27px;
  margin: 0px;
  text-align: center;
}
.calculator {
  height: 180px;
  width: auto;
  align-self: center;
}
.calculatorContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.graph {
  padding: 40px 50px 20px 50px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 50px;
}
.thanks {
  position: absolute;
  bottom: 50;
  left: 100;
  right: 100;
}
