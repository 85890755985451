.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.name {
  margin: 0px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 20px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 20px;
  }
}
.des {
  margin: 0px;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 20px;
  text-align: left;
}
.des2 {
  margin: 0px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 20px;
  text-align: left;
}
.text {
  margin: 0px;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 10px;
}
.text2 {
  margin: 0px;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 10px;
}
.mid {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);
  padding: 30px 50px 40px 50px;
  margin-bottom: 30px;
  margin-top: 20px;
  @media screen and (width< 400px) {
    padding: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 20px;
  }
}
.mid2 {
  border-radius: 12px;
  background: #fff;
  padding: 30px;
  border: 1px solid #e8e8e8;
}
.subContainer {
  padding-left: 8%;
  padding-right: 8%;
  @media screen and (width< 400px) {
    padding: 3%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 6%;
  }
}
.midSubContainer {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
}

.start {
  margin-left: 20px;
  padding: 15px 30px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #3f8ea3;
  cursor: pointer;

  &:hover {
    background: #367a8d;
  }

  p {
    color: #fff;
    padding-right: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0px;
    @media screen and (width< 400px) {
      font-size: 14px;
    }
    @media screen and (width > 400px) and (width < 750px) {
      font-size: 16px;
    }
  }

  @media screen and (width< 400px) {
    padding: 8px 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 8px 20px;
  }
}
