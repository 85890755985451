.customButton {
  background-color: #3f8ea3;
  color: #fff;
  padding: 10px 25px;
  width: fit-content;
  border-radius: 22px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #35778a;
  }
}

.customButtonDisabled {
  background-color: #d4d5d6;
  color: #fff;
  padding: 10px 25px;
  font-weight: 600;
  width: fit-content;
  border-radius: 22px;
  cursor: not-allowed;
}

.blueBg {
  background-color: #3f8ea3;
  color: #fff;
  padding: 15px 40px;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;

  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    background-color: #35778a;
  }
  @media screen and (width< 400px) {
    font-size: 14px;
    padding: 10px 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.whiteBg {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 15px 40px;
  width: fit-content;
  border-radius: 10px;
  cursor: pointer;

  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (width< 400px) {
    font-size: 14px;
    padding: 10px 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 16px;
    padding: 10px 20px;
  }
}

.start {
  align-items: center;
  border-radius: 12px;
  background: #3f8ea3;
  width: 138px;
  height: 40px;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #367a8d;
  }
  p {
    color: #fff;
    padding-right: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  @media screen and (width< 400px) {
    height: 40px;
    width: 110px;
    white-space: nowrap;
  }
}
