.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //   background-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
