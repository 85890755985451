.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  //   background-color: aqua;
  //   padding-bottom: 60px !important;
}
.subContainer {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 30px;
  padding-bottom: 50px;
  @media screen and (width< 400px) {
    padding: 3%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 6%;
  }
}
.name {
  margin: 0px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 20px;
  }
}
.balance {
  border-radius: 88px;
  background: #000;
  border: 1px solid #000;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 42px;
  cursor: pointer;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  //   margin-left: -5px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.balanceInactive {
  border-radius: 88px;
  background: #ffffff;
  border: 1px solid #000;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 42px;
  cursor: pointer;

  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.allocation {
  margin-left: 10px;
  border-radius: 88px;
  border: 1px solid #000;
  background: #000000;
  width: 157px;
  height: 42px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.allocationInactive {
  margin-left: 10px;
  border-radius: 88px;
  border: 1px solid #000;
  background: #fff;
  width: 157px;
  height: 42px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}

.bottomText {
  margin: 0px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.backButton {
  width: 180px;
  height: 50px;
  padding: 0px;
  display: grid;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 12px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
  @media screen and (width< 400px) {
    width: 170px;
  }
}
.start {
  align-items: center;
  border-radius: 12px;
  background: #3f8ea3;
  width: 180px;
  height: 50px;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #367a8d;
  }
  p {
    color: #fff;
    padding-right: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  @media screen and (width< 400px) {
    width: 170px;
  }
}
.bottomContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (width< 667px) {
    display: contents;
  }
}
.buttonContainer {
  align-items: center;
  @media screen and (width< 667px) {
    margin-top: 20px;
  }
}
