.title {
  color: #000;
  margin: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (width< 776px) {
    padding-right: 50px !important;
    font-size: 14px;
  }
  @media screen and (width > 750px) and (width < 1210px) {
    font-size: 16px;
  }
}
.des {
  color: #333;
  margin: 0px !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 10px;
  @media screen and (width< 776px) {
    font-size: 12px;
  }
  @media screen and (width > 750px) and (width < 1210px) {
    font-size: 14px;
  }
}
.yellowBox {
  width: 16px !important;
  height: 16px !important;
  border-radius: 3px;
  background: #fbcd07;
  @media screen and (width< 776px) {
    height: 9px !important;
  }
}
.blueBox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #3f8ea3;
  margin-left: 20px !important;
  @media screen and (width< 776px) {
    height: 9px !important;
  }
}
