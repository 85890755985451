.container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  overflow: scroll;
}
.left {
  width: 37%;
  padding-left: 8%;
  padding-right: 5%;
  padding-top: 10%;
  @media screen and (width< 450px) {
    width: 100vw;
    padding: 20px;
  }
}
.right {
  width: 50%;
  @media screen and (width< 450px) {
    display: none;
  }
}
.img {
  height: 100vh;
  width: 100%;
}
.logo {
  height: 100px;
  width: 149px;
  overflow: scroll;
}
.title {
  color: #1a1a1a;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 41.6px */
}
.name {
  margin-bottom: 20px !important;
  width: 335px;
  height: 56px;
  border-radius: 4px;
}
.des {
  color: #383838;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}
.err {
  color: red !important;
  font-size: 12px;
  margin-top: -15px;
}
.flex {
  width: 335px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
  margin-left: -10px;
}
.innerFlex {
  display: flex;
  align-items: center;
}
.remember {
  color: #383838;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 20.8px */
}
.forgot {
  color: #383838;
  text-align: right;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 20.8px */
  text-decoration-line: underline;
  cursor: pointer;
}
@media screen and (width< 400px) {
}
@media screen and (width > 400px) and (width < 750px) {
}
@media screen and (width > 750px) and (width < 1200px) {
}
@media screen and (width > 1200px) {
}
