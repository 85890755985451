.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  width: 300.341px;
  height: 300px;
  @media screen and (width< 400px) {
    width: 250.341px;
    height: 250px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 280.341px;
    height: 280px;
  }
}
.title {
  color: #000;
  font-family: "Roboto";
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.des {
  color: #383838;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding: 0px 20px;
}
