.progressbarWidth {
  position: relative;
  margin-bottom: 30px;
  margin-top: 20px;
  width: 700px;
  // @media screen and (width< 400px) {
  //   width: 350px;
  // }
  // @media screen and (width > 400px) and (width < 750px) {
  //   width: 400px;
  // }
  // @media screen and (width > 750px) and (width < 1200px) {
  //   width: 650px;
  // }
  // @media screen and (width > 1200px) {
  //   width: 768px;
  // }
}

.percent {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
}
.input {
  width: 335px;
  height: 56px;
  border-radius: 4px;
}
.plus {
  border: 1px solid rgba(159, 159, 159, 0.87);
  border-radius: 100%;
  padding: 17px 20px 17px 20px;
  cursor: pointer;

  color: rgba(159, 159, 159, 0.87);
  font-family: "Roboto";
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px; /* 75% */
  letter-spacing: -0.32px;
  &:hover {
    border: 1px solid #000;
    color: #000;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
