.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.parent {
  position: fixed;
  bottom: 0px;
  width: 90%;
  background-color: #fbfbfb;
}
.child {
  margin: 0 auto;
  width: fit-content;
  padding: 15px;
}
.userList {
  @media screen and (width< 400px) {
    font-size: 22px !important;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 25px !important;
  }
  color: #1c1e2e;
  font-family: "Roboto";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
}
