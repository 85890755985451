.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.subContainer {
  margin-left: 7%;
  margin-right: 7%;
  padding-top: 30px;
  // overflow: hidden;

  @media screen and (width< 400px) {
    padding: 3%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 6%;
  }
}
.name {
  margin: 0px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 20px;
  }
}
.buttonFlex {
  justify-content: flex-end;
  display: flex;
  margin-top: 25px;
  margin-bottom: 50px;
}
.check {
  color: #3f8ea3;
  margin: 0px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  padding-right: 8px;
}
.pieChartFlex {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.pieChartGo {
  display: flex;
  align-items: center;
  border-radius: 88px;
  border: 1px solid #3f8ea3;
  background: #fff;
  width: fit-content;
  cursor: pointer;
  padding: 10px 20px;
}
.targetInput {
  width: 335px;
  height: 56px;
  border-radius: 4px;
}
.plus {
  border: 1px solid rgba(159, 159, 159, 0.87);
  border-radius: 100%;
  padding: 17px 20px 17px 20px;
  cursor: pointer;

  color: rgba(159, 159, 159, 0.87);
  font-family: "Roboto";
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px; /* 75% */
  letter-spacing: -0.32px;
  &:hover {
    border: 1px solid #000;
    color: #000;
  }
}
