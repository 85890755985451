@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Mui-selected {
  /* color: #fff !important; */
  border: none !important;
  background-color: rgb(63, 142, 163) !important;
}
.css-1a59zbu.Mui-selected {
  color: #fff;
}
.MuiChartsTooltip-cell {
  border: none !important;
}
.custom-y-padding-bottom .MuiChartsAxis-directionY .MuiChartsAxis-label {
  transform: translateX(-20px) !important;
}
.css-eqeipb-MuiPaper-root-MuiPopover-paper-MuiMenu-paper::before {
  right: 7px !important;
}
.css-1hiihr-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #e8e8e8 !important;
}
.css-1hiihr-MuiButtonBase-root-MuiMenuItem-root {
  background-color: #fff !important;
}
