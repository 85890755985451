.container {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 30px 50px 30px 30px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.avatar {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.name {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0px !important;
  padding-bottom: 15px;
}
.email {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0px !important;
  line-break: anywhere;
}
.done {
  color: #008000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0px !important;
}
.notDone {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0px !important;
}
.start {
  align-items: center;
  border-radius: 12px;
  background: #3f8ea3;
  width: 138px;
  height: 50px;
  flex-shrink: 0;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #367a8d;
  }
  p {
    color: #fff;
    padding-right: 5px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}
.noData {
  color: gray;
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  align-self: center;
}
.download {
  font-size: 14px;
  text-decoration: underline;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
}
