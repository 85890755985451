.container {
  background-color: #f2f2f2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  height: 50vh;
  width: 50vh;
}
.notFound {
  font-size: 20px;
}
