.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.name {
  margin: 0px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 20px;
  }
}

.wrapperClassName {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.editorClassName {
  min-height: 200px;
  padding: 0px 15px;
  color: #000;
  font-size: 16px;
}

.toolbarClassName {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor {
  margin-top: 30px;
  padding-left: 10%;
  padding-right: 10%;
  @media screen and (width< 400px) {
    padding: 3%;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding: 6%;
  }
}
.des {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
}
