.container {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.name {
  margin: 0px;
  color: #000;
  text-align: left;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  @media screen and (width< 400px) {
    padding-top: 18px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 18px;
  }
}
.balance {
  border-radius: 88px;
  background: #000;
  border: 1px solid #000;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 42px;
  cursor: pointer;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  //   margin-left: -5px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.balanceInactive {
  border-radius: 88px;
  background: #ffffff;
  border: 1px solid #000;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 42px;
  cursor: pointer;

  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.allocation {
  margin-left: 10px;
  border-radius: 88px;
  border: 1px solid #000;
  background: #000000;
  width: 157px;
  height: 42px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.allocationInactive {
  margin-left: 10px;
  border-radius: 88px;
  border: 1px solid #000;
  background: #fff;
  width: 157px;
  height: 42px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
  }
}
.textField {
  width: 100%;
  height: 56px;
}
.buttonFlex {
  justify-content: flex-start;
  display: flex;
  margin-top: 50px;
}
