.img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 239.126px;
  height: 230.328px;
  z-index: -1;
  @media screen and (width< 400px) {
    display: none !important;
  }
}
.titleContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}
.container {
  display: grid;
  align-items: center;
  height: 100vh;
  // width: 100vw;
  z-index: -2;
  padding-left: 10%;
  padding-right: 10%;
  overflow: scroll;
}
.back {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  padding-left: 10px;
}
.title {
  color: var(--Cambo-Neutral-383838, #383838);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.des {
  color: var(--Cambo-Neutral-383838, #383838);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */
}
.header {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-bottom: 0px;
}
.input {
  width: 335px;
  height: 56px;
  border-radius: 4px;
}
.subTitle {
  color: var(--Cambo-Neutral-383838, #383838);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}

@media screen and (width< 400px) {
}
@media screen and (width > 400px) and (width < 750px) {
}
@media screen and (width > 750px) and (width < 1200px) {
}
@media screen and (width > 1200px) {
}
