.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: -2;
  padding-left: 5%;
  padding-right: 5%;
  overflow: scroll;
  background-color: #fbfbfb;
  padding-bottom: 60px !important;
}
.back {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 10px;
}
.details {
  color: #000;
  font-size: 30px;
  line-height: 35.16px;
  font-weight: 600;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.title {
  color: #000;
  font-size: 22px;
  line-height: 28.6px;
  font-weight: 600;
  padding-top: 10px;
}
